
















































































































































import { defineComponent, ref, Ref, computed, reactive } from '@vue/composition-api';
import { getPermissions, savePermission, Permission, deletePermission } from '@/admin/permission';
import { useNotification } from '@/composition/notification';
import { usePayment, SubscriptionPlan } from '@/admin/payment';
import { getProducts, ProductResponse } from '@/admin/product';
import { useConfirm } from '@/composition/confirm';
import FcAuthority from '@/components/FcAuthority.vue';
import { getGifts, GiftResponse } from '@/admin/gift';
import { getSeasons, Season } from '@/admin/auth';
import myAttributes from '@/composition/myAttributes';
import FcRoleLoading from '@/components/FcRoleLoading.vue';
import FcRoleDeny from '@/components/FcRoleDeny.vue';

interface Authority {
  planIds: string[];
  seasonIds: number[];
  productIds: string[];
  giftIds: string[];
  acceptAllUsers: boolean;
  acceptAllMembers: boolean;
  deny: boolean;
}

export default defineComponent({
  name: 'Permissions',
  components: {
    FcAuthority,
    FcRoleLoading,
    FcRoleDeny,
  },
  props: {
    defaultSearchWord: {
      type: String,
      default: '',
    },
    permissionType: {
      type: String,
      default: 'path',
    },
  },
  setup(props) {
    const myRoleSettings = computed(() => myAttributes.getRoleSettings('permissions'));

    const notification = useNotification();
    const { confirmDialog } = useConfirm();
    const { getSubscriptionPlans } = usePayment();

    const headers = [
      { text: 'タイプ', value: 'type' },
      { text: 'URL', value: 'path' },
      { text: '権限', value: 'authority' },
      { text: '', value: 'actions', align: 'end' },
    ];

    const items: Ref<Permission[]> = ref([]);
    const subscriptionPlans: Ref<SubscriptionPlan[]> = ref([]);
    const products: Ref<ProductResponse[]> = ref([]);
    const gifts: Ref<GiftResponse[]> = ref([]);
    const seasons: Ref<Season[]> = ref([]);
    const isLoading = ref(true);

    Promise.all([getPermissions(), getSubscriptionPlans(), getProducts(), getGifts(), getSeasons()])
      .then(([permissions, plans, _products, _gifts, _seasons]) => {
        items.value = permissions;
        subscriptionPlans.value = plans;
        products.value = _products;
        gifts.value = _gifts;
        seasons.value = _seasons;
        isLoading.value = false;
      })
      .catch((error) => {
        notification.error(error);
        isLoading.value = false;
      });

    const getPlanName = (planIds: string[]) => {
      return planIds
        .map((id) => subscriptionPlans.value.find((plan) => plan.subscriptionPlanId === id)?.subscriptionPlanName)
        .join(', ');
    };
    const getProductName = (productIds: string[]) => {
      return productIds.map((id) => products.value.find((product) => product.productId === id)?.productName).join(', ');
    };
    const getGiftName = (giftIds: string[]) => {
      return giftIds.map((id) => gifts.value.find((gift) => gift.giftId === id)?.giftName).join(', ');
    };
    const getSeasonName = (seasonIds: number[]) => {
      return seasonIds.map((id) => seasons.value.find((season) => season.seasonId === id)?.seasonName).join(', ');
    };

    const isSaving = ref(false);
    const isDialogActive = ref(false);
    const isNew = ref(false);
    const permissionCreateType = ref('');
    const permissionPath = ref('');
    const permissionAuthority: Authority = reactive({
      planIds: [],
      seasonIds: [],
      productIds: [],
      giftIds: [],
      acceptAllUsers: false,
      acceptAllMembers: false,
      deny: false,
    });
    const create = () => {
      isDialogActive.value = true;
      isNew.value = true;
      permissionAuthority.planIds = [];
      permissionAuthority.seasonIds = [];
      permissionAuthority.productIds = [];
      permissionAuthority.giftIds = [];
      permissionAuthority.acceptAllUsers = false;
      permissionAuthority.acceptAllMembers = false;
      permissionAuthority.deny = false;
      permissionCreateType.value = props.permissionType;
      permissionPath.value = props.permissionType === 'path' ? '' : props.permissionType;
    };
    const edit = (item: Permission) => {
      isDialogActive.value = true;
      isNew.value = false;
      if (item.planIds?.length) permissionAuthority.planIds = item.planIds;
      else if (item.seasonIds?.length) permissionAuthority.seasonIds = item.seasonIds;
      else if (item.productIds?.length) permissionAuthority.productIds = item.productIds;
      else if (item.giftIds?.length) permissionAuthority.giftIds = item.giftIds;
      else if (item.acceptAllMembers) permissionAuthority.acceptAllMembers = item.acceptAllMembers;
      else if (item.acceptAllUsers) permissionAuthority.acceptAllUsers = item.acceptAllUsers;
      else if (item.deny) permissionAuthority.deny = item.deny;
      else permissionAuthority.acceptAllMembers = true;
      permissionCreateType.value = item.type;
      permissionPath.value = item.path;
    };
    const cancel = () => {
      isNew.value = false;
      isDialogActive.value = false;
      permissionAuthority.planIds = [];
      permissionAuthority.seasonIds = [];
      permissionAuthority.productIds = [];
      permissionAuthority.giftIds = [];
      permissionAuthority.acceptAllUsers = false;
      permissionAuthority.acceptAllMembers = false;
      permissionAuthority.deny = false;
    };
    const save = async () => {
      permissionPath.value = permissionPath.value.trim();
      if (!permissionCreateType.value || !permissionPath.value) {
        // 入力項目のバリデート
        notification.error('パスとタイプは必須です');
        return;
      } else if (permissionPath.value.match(/^\/#\/.*$/)) {
        // パス指定のバリデート
        notification.error('"/#"を含めずに記載してください');
        return;
      } else if (
        props.permissionType === 'path' &&
        !permissionPath.value.match(/.*(\/|\.(json|png|jpeg|jpg|html|mp4|pdf))$/) &&
        !permissionPath.value.match(/^\/(gallery|movie)\/[a-zA-Z0-9]+$/)
      ) {
        // パス指定のバリデート
        notification.error('gallery, movie以外は"/"か".html"で終わるように指定してください');
        return;
      } else if (
        !permissionAuthority.acceptAllUsers &&
        !permissionAuthority.acceptAllMembers &&
        !permissionAuthority.deny &&
        !permissionAuthority.planIds?.length &&
        !permissionAuthority.giftIds?.length &&
        !permissionAuthority.productIds?.length &&
        !permissionAuthority.seasonIds?.length
      ) {
        notification.error('権限で未入力があります');
        return;
      } else if (isNew.value && props.permissionType === 'path') {
        // 新規作成で重複するパスのバリデート
        let duplicatedPath = '';
        items.value.some((item) => {
          const isDuplicated =
            (permissionCreateType.value === item.type && permissionPath.value.includes(item.path)) ||
            item.path.includes(permissionPath.value);
          if (isDuplicated) duplicatedPath = item.path;
          return isDuplicated;
        });
        if (duplicatedPath) {
          notification.error(`${duplicatedPath}と権限が重複しています。`);
          return;
        }
      }
      const permissionProps = {
        type: permissionCreateType.value,
        path: permissionPath.value,
        ...permissionAuthority,
      };
      const method = isNew.value ? 'POST' : 'PUT';
      isSaving.value = true;
      try {
        await savePermission(permissionProps, method);
        notification.notify('保存しました');
        isLoading.value = true;
        items.value = await getPermissions();
        isLoading.value = false;
      } catch (error) {
        notification.error(error);
      }
      isNew.value = false;
      isSaving.value = false;
      cancel();
    };
    const remove = async (item: Permission) => {
      if (!(await confirmDialog('本当に削除しますか？'))) return;
      await deletePermission(item.type, item.path);
      const index = items.value.indexOf(item);
      items.value.splice(index, 1);
    };

    // タイプで絞り込み
    const type = ref(props.permissionType);
    const types = [
      { text: 'パス', value: 'path' },
      { text: 'ECサイト', value: 'ec' },
      { text: '誕生日メール', value: 'birthday' },
    ];
    const getTypeName = (value: string) => types.find((type) => type.value === value)?.text;
    if (!types.find((type) => props.permissionType === type.value))
      notification.error('パス（タイプ）の指定に誤りがあります');

    // 権限タイプで絞り込み
    const authorityType = ref('');
    const authorityTypes = [
      { text: '全てのユーザー', value: 'allUsers' },
      { text: '無料含む全ての会員', value: 'allMembers' },
      { text: 'プラン', value: 'plan' },
      { text: '個別課金', value: 'product' },
      { text: '特典', value: 'gift' },
      { text: 'シーズン', value: 'season' },
      { text: '公式のみ', value: 'deny' },
    ];

    // フリーワードで絞り込み
    const searchWord = ref(props.defaultSearchWord);

    // 表示するアイテム一覧
    const permissions = computed(() => {
      return (
        items.value
          // トークルームと会員証の権限をここから削除
          .filter((item) => !(item.path.match(/^talkroom\/talkrooms.*/) || item.path.match(/^(\/)?membershipCards.*/)))
          // 推しの権限を削除
          .filter((item) => item.type !== 'favoriteItem')
          .filter((item) => !type.value || item.type === type.value)
          .filter(
            (item) =>
              !authorityType.value ||
              (authorityType.value === 'allUsers' && item.acceptAllUsers) ||
              (authorityType.value === 'allMembers' && item.acceptAllMembers) ||
              (authorityType.value === 'plan' && item.planIds?.length) ||
              (authorityType.value === 'product' && item.productIds?.length) ||
              (authorityType.value === 'gift' && item.giftIds?.length) ||
              (authorityType.value === 'season' && item.seasonIds?.length) ||
              (authorityType.value === 'deny' && item.deny)
          )
          .filter((item) => !searchWord.value || JSON.stringify(item).includes(searchWord.value))
      );
    });

    // テーブル高さ
    const tableHeight = ref(600);
    const elements = document.getElementsByClassName('v-main__wrap');
    if (elements.length) {
      tableHeight.value = elements[0].clientHeight - 64 - 40;
    }

    return {
      pageTitle: '閲覧権限',
      myRoleSettings,
      headers,
      isLoading,
      isSaving,
      type,
      types,
      authorityType,
      authorityTypes,
      searchWord,
      permissions,
      tableHeight,
      permissionCreateType,
      permissionPath,
      permissionAuthority,
      isNew,
      isDialogActive,
      edit,
      create,
      save,
      cancel,
      remove,
      getPlanName,
      getTypeName,
      getProductName,
      getGiftName,
      getSeasonName,
    };
  },
});
